import React from 'react';
import Layout from 'Containers/Layout';
import Test from 'Containers/Test';
import colors from 'theme/colors';
import Footer, { FooterThemes } from 'Components/Footer';
import { EnumPages } from '../constants';
import Navbar, { NavbarPages } from 'Components/Navbar';

const testTheme = {
  bgColor: colors.palePurple,
  body: {
    color: colors.purple,
  },
  disclaimer: {
    bgColor: colors.purple,
    color: colors.palePurple,
  },
};

const TestPage: React.FC = () => (
  <Layout theme={testTheme}>
    <Navbar page={NavbarPages.TEST} />
    <Test />
    <Footer theme={FooterThemes[EnumPages.TEST]} />
  </Layout>
);

export default TestPage;
